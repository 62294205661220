/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.JobsPage {
  background: #fff;
}
.JobsPage .aboveFold {
  overflow: hidden;
  position: relative;
  background: #281734;
  min-height: 57rem;
}
@media (max-width: 767px) {
  .JobsPage .aboveFold {
    min-height: 54rem;
  }
}
.JobsPage .aboveFold .maxWidthContentWrapper {
  padding: 8rem;
}
@media (max-width: 767px) {
  .JobsPage .aboveFold .maxWidthContentWrapper {
    padding: 3rem;
  }
}
.JobsPage .aboveFold .maxWidthContentWrapper > .title {
  color: #fff;
  font-weight: 600;
  font-size: 5rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 7.5rem;
  max-width: 75rem;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}
@media (max-width: 767px) {
  .JobsPage .aboveFold .maxWidthContentWrapper > .title {
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0;
    margin-top: 3rem;
  }
}
.JobsPage .aboveFold .maxWidthContentWrapper > .intro {
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  max-width: 50rem;
  margin: 3rem auto 0;
  position: relative;
  z-index: 100;
}
@media (max-width: 767px) {
  .JobsPage .aboveFold .maxWidthContentWrapper > .intro {
    font-size: 1.8em;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.JobsPage .aboveFold .maxWidthContentWrapper > .intro a {
  color: white;
}
.JobsPage .aboveFold .maxWidthContentWrapper > .intro a:hover {
  color: white;
  text-decoration: underline;
}
.JobsPage .aboveFold .maxWidthContentWrapper > .topLeft {
  position: absolute;
  left: -6rem;
  top: 15rem;
}
@media (max-width: 1199px) {
  .JobsPage .aboveFold .maxWidthContentWrapper > .topLeft {
    display: none;
  }
}
.JobsPage .aboveFold .maxWidthContentWrapper > .topRight {
  position: absolute;
  right: 0;
  bottom: -6rem;
  z-index: 101;
}
@media (max-width: 1199px) {
  .JobsPage .aboveFold .maxWidthContentWrapper > .topRight {
    display: none;
  }
}
.JobsPage .StatsRow {
  margin: 0 auto;
}
.JobsPage .bigMission,
.JobsPage .ourProduct,
.JobsPage .perks {
  border-bottom: 0.1rem solid #f9f6ef;
  padding-bottom: 5rem;
  padding-left: 8rem;
  padding-right: 8rem;
  position: relative;
  overflow: hidden;
  background-color: white;
}
@media (max-width: 767px) {
  .JobsPage .bigMission,
  .JobsPage .ourProduct,
  .JobsPage .perks {
    padding-left: 3rem;
    padding-right: 3rem;
    margin: 5rem auto 0;
  }
}
.JobsPage .bigMission .title,
.JobsPage .ourProduct .title,
.JobsPage .perks .title {
  color: #745a9e;
  font-size: 3.6rem;
  font-weight: 600;
  margin-top: 8rem;
}
@media (max-width: 1199px) {
  .JobsPage .bigMission .title,
  .JobsPage .ourProduct .title,
  .JobsPage .perks .title {
    margin-top: 2rem;
  }
}
.JobsPage .bigMission .description,
.JobsPage .ourProduct .description,
.JobsPage .perks .description {
  color: #534468;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 3rem;
  padding-right: 3rem;
  max-width: 60rem;
}
@media (max-width: 767px) {
  .JobsPage .bigMission .description,
  .JobsPage .ourProduct .description,
  .JobsPage .perks .description {
    margin-bottom: 3rem;
  }
}
.JobsPage .bigMission .RoundButton,
.JobsPage .ourProduct .RoundButton,
.JobsPage .perks .RoundButton {
  margin-top: 3rem;
}
.JobsPage .meetTeam {
  padding: 7rem;
  background: #534468;
  position: relative;
  overflow: hidden;
  min-height: 36rem;
}
@media (max-width: 767px) {
  .JobsPage .meetTeam {
    display: none;
  }
}
.JobsPage .meetTeam .teamLogo {
  width: 40%;
  position: relative;
  left: 0;
}
@media (max-width: 767px) {
  .JobsPage .meetTeam .teamLogo {
    width: 100%;
  }
}
.JobsPage .meetTeam .cloud1 {
  position: absolute;
  left: -12rem;
  top: 22rem;
}
.JobsPage .meetTeam .cloud2 {
  position: absolute;
  right: -12rem;
  top: 3rem;
}
.JobsPage .meetTeam .teamMembers {
  width: 50%;
  position: absolute;
  right: 0;
  top: 6rem;
}
@media (max-width: 767px) {
  .JobsPage .meetTeam .teamMembers {
    width: 100%;
    top: 19rem;
  }
}
.JobsPage .meetTeam .teamMembers .TeamMember,
.JobsPage .meetTeam .teamMembers .seeAll {
  width: 33%;
  float: left;
  margin-top: 2.4rem;
}
.JobsPage .meetTeam .teamMembers .TeamMember {
  cursor: default;
  font-weight: 600;
}
.JobsPage .meetTeam .teamMembers .TeamMember .headshotFrame > .photo {
  border: 0.8rem solid #402e59;
  width: 13rem;
  height: 13rem;
  border-radius: 100%;
}
.JobsPage .meetTeam .teamMembers .TeamMember .name {
  color: #fff;
  font-size: 1.6rem;
  margin-top: 1rem;
}
.JobsPage .meetTeam .teamMembers .TeamMember .jobTitle {
  color: #fff;
  font-size: 1.1rem;
}
.JobsPage .meetTeam .teamMembers .seeAll {
  cursor: pointer;
  width: 13rem;
  height: 13rem;
}
.JobsPage .opportunities {
  background: #f9f6ef;
  padding: 12rem;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .JobsPage .opportunities {
    padding: 4rem;
  }
}
.JobsPage .opportunities .title {
  text-align: center;
  font-size: 3.8rem;
  font-weight: 600;
  color: #745a9e;
}
.JobsPage .opportunities .cloud1 {
  position: absolute;
  left: -16rem;
  top: 12rem;
}
@media (max-width: 1199px) {
  .JobsPage .opportunities .cloud1 {
    display: none;
  }
}
.JobsPage .opportunities .cloud2 {
  position: absolute;
  left: 40rem;
  bottom: 2rem;
}
@media (max-width: 1199px) {
  .JobsPage .opportunities .cloud2 {
    display: none;
  }
}
.JobsPage .opportunities .cloud3 {
  position: absolute;
  right: -16rem;
  top: 35rem;
}
@media (max-width: 1199px) {
  .JobsPage .opportunities .cloud3 {
    display: none;
  }
}
.JobsPage .opportunities .header {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 600;
  color: #534468;
  text-align: left;
  margin-top: 5rem;
  display: flex;
}
.JobsPage .opportunities .header .line {
  flex: 1;
  margin: 1rem 2rem;
  height: 0.1rem;
  background: #e6e0d0;
  float: right;
}
