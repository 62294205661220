/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.StatsRow {
  max-width: 100rem;
  padding: 3rem;
  margin-top: 2rem;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .StatsRow {
    padding: 2rem;
  }
}
.StatsRow .statistic.fiveAcross {
  width: 20%;
  display: inline-block;
}
.StatsRow .statistic > .value {
  font-size: 2.9rem;
  font-weight: bold;
}
@media (max-width: 767px) {
  .StatsRow .statistic > .value {
    font-size: 2rem;
  }
}
.StatsRow .statistic > .value.green {
  color: #9cd541;
}
.StatsRow .statistic > .value.red {
  color: #ff486b;
}
.StatsRow .statistic > .value.purple {
  color: #745a9e;
}
.StatsRow .statistic > .value.blue {
  color: #4a82ff;
}
.StatsRow .statistic > .value.yellow {
  color: #ffdc4a;
}
.StatsRow .statistic > .key {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .StatsRow .statistic > .key {
    font-size: 0.8rem;
  }
}
